@font-face {
  font-family: "vwheadbold";
  src: url("../../assets/fonts/vwhead-bold-webfont.woff2") format("woff2"), url("../../assets/fonts/vwhead-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "vwheadlight";
  src: url("../../assets/fonts/vwhead-light-webfont.woff2") format("woff2"), url("../../assets/fonts/vwhead-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vwhead";
  src: url("../../assets/fonts/vwhead-webfont.woff2") format("woff2"), url("../../assets/fonts/vwhead-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "vwtext";
  src: url("../../assets/fonts/vwtext-webfont.woff2") format("woff2"), url("../../assets/fonts/vwtext-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "vwheadlight", sans-serif;
  font-weight: 300;
  letter-spacing: -0.01rem;
  & strong,
  & *.bold {
    font-family: "vwhead", sans-serif;
  }
}

.container {
  max-width: 1600px !important;
}

.border {
  border: 2px solid #d9d9d9 !important;
}

//text styles

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1);
  font-family: "vwhead", sans-serif;

  & strong,
  & *.bold {
    font-family: "vwheadbold", sans-serif;
  }
}

// text styles

.text-primary {
  color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
}

.text-secondary {
  color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1) !important;
}

// end of text styles

// background-styles
.bg-primary {
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
}

.bg-secondary {
  background-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1) !important;
}

.bg-tertiary {
  background-color: hsla(var(--tertiary-h), var(--tertiary-s), var(--tertiary-l), 1) !important;
}

// end of background-styles

//? Material Icon sizing classes

.mi-12 {
  font-size: 12px;
}

.mi-14 {
  font-size: 14px;
}

.mi-18 {
  font-size: 18px;
}

.mi-36 {
  font-size: 36px;
}

//Margin classes
.mb-6 {
  margin-bottom: 6rem !important;
}
//End of margin classes

.mb-15 {
  margin-bottom: 15rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

.tooltip {
  @media (max-width: 540px) {
    display: none !important;
  }
}

//? End of Material Icon sizing classes

//? Button styling classes

.btn {
  & svg {
    vertical-align: middle;
    line-height: 0 !important;
    position: relative;
    top: -2px;
  }
  border-radius: 1.4rem;
  font-weight: 400;
  padding: 0.625rem 3rem;
}

.btn-primary {
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
  border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);

  &:hover {
    border-color: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 10%), 1);
    background-color: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 10%), 1);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    pointer-events: none;
  }
}

.page-background {
  background-color: #ffffff;
  min-height: calc(100vh - 120px);
}

.btn-secondary {
  background-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1);
  border-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1);

  &:hover {
    border-color: hsla(var(--secondary-h), var(--secondary-s), calc(var(--secondary-l) + 10%), 1);
    background-color: hsla(var(--secondary-h), var(--secondary-s), calc(var(--secondary-l) + 10%), 1);
  }
}

.btn-success {
  background-color: hsla(var(--quaternary-h), var(--quaternary-s), var(--quaternary-l), 1);
  border-color: hsla(var(--quaternary-h), var(--quaternary-s), var(--quaternary-l), 1);

  &:hover {
    border-color: hsla(var(--quaternary-h), var(--quaternary-s), calc(var(--quaternary-l) + 10%), 1);
    background-color: hsla(var(--quaternary-h), var(--quaternary-s), calc(var(--quaternary-l) + 10%), 1);
  }
}

.btn-confirmed {
  color: #ffffff;
  background-color: hsla(var(--quinary-h), var(--quinary-s), var(--quinary-l), 1);
  border-color: hsla(var(--quinary-h), var(--quinary-s), var(--quinary-l), 1);

  &:hover {
    color: #ffffff;
    border-color: hsla(var(--quinary-h), var(--quinary-s), calc(var(--quinary-l) + 10%), 1);
    background-color: hsla(var(--quinary-h), var(--quinary-s), calc(var(--quinary-l) + 10%), 1);
  }
}

//? End of Button styling classes

//Input Styling

.form-control {
  color: #000000;
  border: 3px solid hsla(var(--tertiary-h), var(--tertiary-s), calc(var(--tertiary-l) - 8%), 0.7);
  padding: 22px 15px;

  &:focus {
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 3px solid hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    color: #000000;
  }
}

.select {
  color: #000000;
  position: relative;
  display: flex;
  width: 100%;
  height: 3em;
  border: 3px solid hsla(var(--tertiary-h), var(--tertiary-s), calc(var(--tertiary-l) - 8%), 0.7);
  border-radius: 0.25rem;

  &:focus,
  &:active {
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 3px solid hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    color: #000000;
  }
}

select {
  padding: 0 15px;
  cursor: pointer;
}

.form-control.select {
  color: #000000;
  border: 3px solid hsla(var(--tertiary-h), var(--tertiary-s), calc(var(--tertiary-l) - 8%), 0.7);
  // padding: 22px 15px;
  line-height: 0px;
  height: 3em;

  &:focus {
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 3px solid hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    color: #000000;
  }
}

input:required {
  box-shadow: none !important;
}

//End of Input Styling classes

// Radio and Checkbox Styling

.radio {
  position: relative;
  width: 160px;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}
input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
  border-radius: 50%;
}

.radio:hover input ~ .checkmark {
  background-color: #ccc;
  cursor: pointer;
}

.radio input:checked ~ .checkmark {
  border: 2px solid hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio input:checked ~ .checkmark:after {
  display: block;
}

.radio .checkmark:after {
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
}

.radio label {
  color: #252525;
  padding-left: 2rem;
  // padding-right: 2rem;
  cursor: pointer;
}

//End of Radio and Checkbox Styling

.event-steps {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

//? Nav Pills Styling classes

.nav-link {
  font-family: "vwtwext", sans-serif;
}
.nav-link.active {
  border-radius: 0px;
  border: 3px solid hsla(var(--primary-h), var(--primary-s), (var(--primary-l)), 1) !important;
  background-color: hsla(var(--primary-h), var(--primary-s), (var(--primary-l)), 1) !important;
  color: #ffffff;
  pointer-events: none;
}

.nav-link.completed {
  border: 3px solid hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
  border-radius: 0px;
  background-color: transparent !important;
  color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
}
.nav-link.disabled {
  border: 3px solid hsla(var(--tertiary-h), var(--tertiary-s), var(--tertiary-l), 1);
  border-radius: 0px;
  background-color: transparent !important;
  color: hsla(var(--tertiary-h), var(--tertiary-s), var(--tertiary-l), 1) !important;
}

//? End of Nav Pills Styling classes

// Fix for autofill styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #8b8b8b !important;
}

// End of Fix for autofill styling

//Media Queries

.event-steps h1,
.event-steps .h1 {
  @media only screen and (max-width: 575px) {
    font-size: 1.75rem;
  }
}

.page-title{
  @media only screen and (max-width: 575px) {
    font-size: 1.725rem;
  }
}


.page-sub-title{
  @media only screen and (max-width: 575px) {
    font-size: 1.2rem;
  }
}
//End Of Media Queries
